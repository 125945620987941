/* eslint-disable no-nested-ternary */
// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import { Button, Box } from '@material-ui/core';
import {
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Table,
} from 'nutrien-common-components-react/dist/mdc/Table';
import getAccountData from 'utils/accountUtils';
// Shared
import { currency } from 'shared/utils/numberFormatters';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import UserPropType from 'shared/config/userPropType';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import AccountDescriptionText from 'shared/ui/AccountDescriptionText';
import HubPaymentsModal from 'shared/ui/HubPaymentsModal';
import paidInvoice from '../../../assets/InvoicePaid-02.svg';
// Constants
import { accountHeaders } from './constants';
import { PAYMENT_FLOW_TYPES } from '../../../components/Payments/PaymentConstants';

class AccountsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubModalOpen: false,
      selectedAccountId: null,
    };
  }

  componentDidMount() {
    const { user, accounts } = this.props;
    if (size(accounts.list) === 0) {
      this.props.fetchAssociatedOrgsAction(
        (user.userDetails || {}).associatedOrgs || []
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { user: prevUser } = prevProps;
    const { user } = this.props;
    if (user.id !== prevUser.id) {
      this.props.fetchAssociatedOrgsAction(
        (user.userDetails || {}).associatedOrgs || []
      );
    }
  }

  handleViewDetailsClick = (accountId) => {
    const {
      flags: { universalHeader },
    } = this.props;

    this.props.push({
      pathname: `/accounts/${accountId}/overview`,
      search: universalHeader ? `acct=${accountId}` : null,
    });
    window.scroll(0, 0);
  };

  handlePaymentClick = (account) => {
    const { cxhPaymentsRedirectToHub } = this.props.flags;
    const { push, updatePaymentForm, setPaymentFlowType } = this.props;

    if (cxhPaymentsRedirectToHub) {
      this.setState({ hubModalOpen: true, selectedAccountId: account.id });
    } else {
      setPaymentFlowType(PAYMENT_FLOW_TYPES.MAKE_A_PAYMENT);
      updatePaymentForm({
        cpsAccount: account,
      });

      push({
        pathname: `/make-a-secure-payment`,
        search: `acct=${account.id}`,
      });

      window.scroll(0, 0);
    }
  };

  populateColumnData = (columns, columnData, header, colSpan, align) => {
    if (columnData !== null) {
      columns.push(
        <TableCell
          key={`${header}-${columnData}-desktop`}
          colSpan={colSpan}
          className="accounts__table-mobile-display-none"
          align={align}
        >
          {columnData}
        </TableCell>
      );
    }
    // This is needed for mobile display
    if (
      header !== null &&
      header !== '' &&
      columnData !== null &&
      columnData !== ''
    ) {
      columns.push(
        <TableCell
          key={`${header}-${columnData}-mobile`}
          className={`accounts__table-item-desktop-display-none ${
            header === 'Description' && 'accounts__table-description-column'
          } ${
            header === 'Currently Due' && 'accounts__table-currentlyDue-column'
          }`}
        >
          {header !== 'Description' && (
            <span className="accounts__table-item-mobile-display">
              {header}
            </span>
          )}

          {columnData}
        </TableCell>
      );
    }
    return columns;
  };

  populateColumnDataWithCurrency = (columnData, accountHeader, columns) =>
    // We don't want to show the prepayBalance column if the value is 0.
    ((accountHeader.primaryColumns &&
      accountHeader.primaryColumns.includes('prepayBalance')) ||
      (accountHeader.backupColumns &&
        accountHeader.backupColumns.includes('prepayBalance'))) &&
    columnData === 0
      ? this.populateColumnData(columns, '', '', 1)
      : this.populateColumnData(
          columns,
          currency(columnData),
          accountHeader.header,
          accountHeader.colSpan,
          accountHeader.align
        );

  populateColumnDataWithLink = (id, columnData, accountHeader, columns) => {
    const {
      flags: { universalHeader },
    } = this.props;

    const URL = universalHeader
      ? `/accounts/${id}/overview?acct=${id}`
      : `/accounts/${id}/overview`;
    const link = (
      <Link
        className="accounts__table-description"
        data-test="account-table-description"
        to={URL}
      >
        {columnData}
      </Link>
    );
    return this.populateColumnData(
      columns,
      link,
      accountHeader.header,
      accountHeader.colSpan
    );
  };

  renderTableHeadersRow = () => (
    <TableHead
      key="accounts-table-head"
      className="accounts__table-mobile-display-none"
    >
      <TableRow>
        {accountHeaders
          .map((accountHeader) => (
            <TableCell
              key={`${accountHeader.header}-column`}
              colSpan={accountHeader.colSpan}
              className="accounts__table-mobile-display-none"
              align={accountHeader.align}
            >
              {accountHeader.header}
            </TableCell>
          ))
          // add button columns last
          .concat(
            <Fragment key="accounts-table-button-columns">
              <TableCell
                key="view-details-button-column"
                className="accounts__button-column"
              />
              <TableCell
                key="pay-button-column"
                className="accounts__button-column"
              />
            </Fragment>
          )}
      </TableRow>
    </TableHead>
  );

  renderColumns = (account) => {
    let columns = [];
    for (let j = 0; j < accountHeaders.length; j += 1) {
      let columnData;
      if (accountHeaders[j].header === 'Description') {
        columnData = (
          <AccountDescriptionText
            key={`account-description-${account.id}`}
            account={account}
          />
        );
      } else {
        columnData = getAccountData(
          account,
          accountHeaders[j].primaryColumns,
          accountHeaders[j].backupColumns,
          accountHeaders[j].formatType
        );
      }
      switch (accountHeaders[j].type) {
        case 'double':
          columns = this.populateColumnDataWithCurrency(
            columnData,
            accountHeaders[j],
            columns
          );
          break;
        case 'link':
          columns = this.populateColumnDataWithLink(
            account.id,
            columnData,
            accountHeaders[j],
            columns
          );
          break;
        default:
          columns = this.populateColumnData(
            columns,
            columnData,
            accountHeaders[j].header,
            accountHeaders[j].colSpan
          );
      }
    }
    columns.push(this.renderViewDetailsContainer(account.id));
    columns.push(this.renderPayContainer(account));
    return columns;
  };

  renderRows = (accounts) => {
    const rows = [];
    for (let i = 0; i < accounts.length; i += 1) {
      const columns = this.renderColumns(accounts[i]);
      rows.push(
        <TableRow
          key={`row-${accounts[i].id}`}
          className="accounts__table-row accounts__table-row__card-wrapper"
        >
          {columns}
        </TableRow>
      );
    }
    return <>{rows}</>;
  };

  renderData = (truncatedAccounts) => <>{this.renderRows(truncatedAccounts)}</>;

  renderPayButtonFeatureFlag = (account) => {
    const { payments, canadaPayments, southernOhioDivision } = this.props.flags;
    const isCanada = this.props.accounts.list[0].locationCode === 'CAN';
    const isFuelCustomer = account.calculatedCustomerType === 'Fuel';
    // TODO: remove usaSapAccount restrictions once SAP is rolled out to entire US market
    const paymentDisabled =
      (account.usaSapAccount && !southernOhioDivision) ||
      account.invalidCanadianAccount ||
      account.invalidInternationalAccount;

    return (
      <DisabledForEmployees>
        <Button
          className="accounts__button-make-payment"
          data-test="pay-now-button"
          onClick={() => this.handlePaymentClick(account)}
          disabled={
            !payments ||
            (isCanada && !canadaPayments) ||
            isFuelCustomer ||
            paymentDisabled
          }
          variant="contained"
        >
          Pay Now
        </Button>
      </DisabledForEmployees>
    );
  };

  renderViewDetailsButton = (accountId) => (
    <Button
      className="accounts__button-view-details"
      data-test="accounts-button-view-details"
      variant="outlined"
      onClick={() => this.handleViewDetailsClick(accountId)}
    >
      View Details
    </Button>
  );

  renderViewDetailsContainer = (accountId) => (
    <td
      key="view-details-container"
      className="accounts__table-button-block accounts__button-block-first"
    >
      {this.renderViewDetailsButton(accountId)}
    </td>
  );

  renderPayContainer = (account) => {
    const { accountPaidInvoiceStamp } = this.props.flags;
    return (
      <td
        key="pay-container"
        className="accounts__table-button-block accounts__table-payment-block"
      >
        {accountPaidInvoiceStamp ? (
          account.currentDue !== 0 ? (
            this.renderPayButtonFeatureFlag(account)
          ) : (
            <img
              src={paidInvoice}
              alt="paid invoice img"
              className="accounts__table-paid-invoice-stamp"
            />
          )
        ) : (
          this.renderPayButtonFeatureFlag(account)
        )}
      </td>
    );
  };

  render() {
    const sortedAccounts = orderBy(
      this.props.accounts.list,
      ['currentDue', 'name'],
      ['desc', 'asc']
    );
    const truncatedAccounts =
      this.props.truncRows !== null
        ? sortedAccounts.slice(0, this.props.truncRows)
        : sortedAccounts;

    const accountsTable = (
      <Box px={-1}>
        <HubPaymentsModal
          isOpen={this.state.hubModalOpen}
          closeDialog={() => this.setState({ hubModalOpen: false })}
          accountId={this.state.selectedAccountId}
        />
        <Table className="accounts__table">
          {this.renderTableHeadersRow()}
          <TableBody className="accounts__table-body">
            {this.renderData(truncatedAccounts)}
          </TableBody>
        </Table>
      </Box>
    );

    let accountsContent;
    switch (this.props.accounts.status) {
      case 'LOADING':
      case 'FETCHING':
        accountsContent = <MaterialLoadingIcon />;
        break;
      case 'OK':
        accountsContent = accountsTable;
        break;
      case 'NO_ACCOUNT':
        accountsContent = <ApiErrorMessage entityName="Accounts" />;
        break;
      default:
        break;
    }

    return <div>{accountsContent}</div>;
  }
}

AccountsTable.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  push: PropTypes.func.isRequired,
  user: UserPropType.isRequired,
  flags: PropTypes.shape({
    payments: PropTypes.bool,
    canadaPayments: PropTypes.bool,
    southernOhioDivision: PropTypes.bool,
    accountPaidInvoiceStamp: PropTypes.bool,
    universalHeader: PropTypes.bool,
  }).isRequired,
  accounts: PropTypes.shape({
    status: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        balance: PropTypes.number,
        due: PropTypes.number,
        pastDue: PropTypes.number,
        number: PropTypes.string,
        locationCode: PropTypes.string,
      })
    ),
  }).isRequired,
  truncRows: PropTypes.number,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  fetchAssociatedOrgsAction: PropTypes.func.isRequired,
  updatePaymentForm: PropTypes.func.isRequired,
  setPaymentFlowType: PropTypes.func.isRequired,
};

AccountsTable.defaultProps = {
  truncRows: null,
};

export default withRouter(AccountsTable);
