import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import Tooltip from '@nutrien/uet-react/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { gtmAccountOverviewInteraction } from 'store/middleware/TagManager/gtmActions';
import currency from 'shared/utils/numberFormatters/currency';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import MaterialButtonYellow from 'shared/ui/MaterialButtonYellow';
import { InfoCard } from 'shared/ui/InfoCard';
import linkArrow from 'assets/arrows--white.svg';
import HelpIcon from 'assets/help.svg';
import HubPaymentsModal from 'shared/ui/HubPaymentsModal';
import useStyles from '../styles';
import {
  updateBankAccountForm,
  setPaymentFlowType,
} from '../../../../Payments/Redux/actions/BankAccountFormActions';
import { PAYMENT_FLOW_TYPES } from '../../../../Payments/PaymentConstants';

const CurrentDueCard = ({ account }) => {
  const [hubModalOpen, setHubModalOpen] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    payments,
    canadaPayments,
    southernOhioDivision,
    cxhPaymentsRedirectToHub,
  } = useFlags();

  const isFuelCustomer = account.calculatedCustomerType === 'Fuel';

  const isInvalidAccount =
    (account.usaSapAccount && !southernOhioDivision) ||
    account.invalidCanadianAccount ||
    account.invalidInternationalAccount;

  const handlePaymentClick = () => {
    if (cxhPaymentsRedirectToHub) {
      setHubModalOpen(true);
    } else {
      dispatch(
        gtmAccountOverviewInteraction('Click', 'Currently Due | Pay Now Button')
      );

      dispatch(setPaymentFlowType(PAYMENT_FLOW_TYPES.MAKE_A_PAYMENT));
      dispatch(
        updateBankAccountForm({
          cpsAccount: account,
        })
      );

      dispatch(
        push({
          pathname: `/make-a-secure-payment`,
        })
      );
      window.scroll(0, 0);
    }
  };

  const availableItemType =
    account.locationCode === 'CAN' ? 'rebates' : 'discounts';
  const tooltipTitle = `This is the balance that is due on the account NOW, including available ${availableItemType} and excluding prepay items.`;

  const currentDueCardTitle = (
    <>
      <Typography component="span">Currently Due </Typography>
      <Tooltip
        title={tooltipTitle}
        placement="top-start"
        enterTouchDelay={0}
        leaveTouchDelay={7000}
        data-test="current-due-tooltip"
        onOpen={() =>
          dispatch(
            gtmAccountOverviewInteraction(
              'Hover',
              'Currently Due | Question Mark'
            )
          )
        }
      >
        <img
          src={HelpIcon}
          className={classes.accountOverviewMoreInfoIcon}
          data-test="current-due-tooltip-icon"
          alt="click for more information"
        />
      </Tooltip>
    </>
  );

  return (
    <InfoCard title={currentDueCardTitle}>
      <HubPaymentsModal
        isOpen={hubModalOpen}
        closeDialog={() => setHubModalOpen(false)}
        accountId={account.id}
      />
      <h1 className="info-card-h1">
        <span data-test="current-due-amount">
          {currency(account.currentDue, '')}
        </span>
        <DisabledForEmployees>
          <MaterialButtonYellow
            className={classes.overviewPayNowButton}
            data-test="current-due-pay-now-button"
            onClick={handlePaymentClick}
            disabled={
              !payments ||
              (!canadaPayments && account.locationCode === 'CAN') ||
              isFuelCustomer ||
              isInvalidAccount
            }
          >
            Pay Now
          </MaterialButtonYellow>
        </DisabledForEmployees>
      </h1>
      <p className="info-card-subtitle">
        <Link
          className={classes.overviewInfoCardLink}
          to={`/accounts/${account.id}/open-items?dueFilter=currentDue`}
          onClick={() =>
            dispatch(
              gtmAccountOverviewInteraction(
                'Click',
                'Currently Due | View Details Link'
              )
            )
          }
        >
          View Details
          <img
            className={classes.overviewInfoCardLinkArrow}
            src={linkArrow}
            alt=""
          />
        </Link>
      </p>
    </InfoCard>
  );
};

CurrentDueCard.propTypes = {
  account: PropTypes.shape({
    currentDue: PropTypes.number.isRequired,
    calculatedCustomerType: PropTypes.string.isRequired,
    locationCode: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default CurrentDueCard;
