import React from 'react';
import propTypes from 'prop-types';
import Icon from 'nutrien-common-components-react/dist/mdc/Icon';
import { IconButton, Button } from '@material-ui/core';
import KEY_ENUM from 'shared/utils/KeyCodes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  gtmRedirectToNewWeb,
  gtmNutrienHubAppleApp,
} from 'store/middleware/TagManager/gtmActions';
import { useDispatch } from 'react-redux';
import resolveHubUrl from 'shared/config/resolveHubUrl';
import { Dialog } from 'shared/ui/MaterialDialog';
import AppleBadge from '../../../assets/download_AppStore.png';
import { APPLE_LINK } from '../constants';
import './HubModalV2.scss';
import HubPlatforms from '../../../assets/hub-platforms.png';
import HubAppIcon from '../../../assets/icon-na-ios-prd.png';

const HubModalV2 = ({ isOpen, closeDialog, preventDismiss = false }) => {
  const isTabletDn = useMediaQuery('(max-width: 1023px)'); // tablet breakpoints
  const isNative = window.ReactNativeWebView;

  const dispatch = useDispatch();

  const handleAppStoreHubClick = () => {
    dispatch(gtmNutrienHubAppleApp());
  };

  const mobileAppleStoreButton = () => {
    return (
      <>
        <a
          href={APPLE_LINK}
          rel="noreferrer noopener"
          target="_blank"
          onClick={() => handleAppStoreHubClick()}
        >
          <img
            src={AppleBadge}
            alt="Click to view the Hub app in the Apple App Store"
            className="hub-intercept-modal__native-badge"
          />
        </a>
      </>
    );
  };

  const handleWebHubClick = () => {
    dispatch(gtmRedirectToNewWeb());
  };

  const desktopHubButton = () => {
    return (
      <>
        <Button
          className="hub-intercept-modal__web-button"
          variant="contained"
          href={resolveHubUrl()}
          rel="noopener"
          target="_blank"
          onClick={handleWebHubClick}
        >
          Explore the New Hub
        </Button>
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeDialog()}
      disableBackdropClick={preventDismiss}
      disableEscapeKeyDown={preventDismiss}
      maxWidth={isTabletDn ? 'xs' : 'md'}
    >
      <div className="hub-intercept-modal__container">
        {!preventDismiss && (
          <div className="hub-intercept-modal__close-container">
            <IconButton
              aria-label="Click to close Hub Ad Dialog"
              onClick={() => closeDialog()}
              onKeyPress={(event) => {
                if (
                  event.key === KEY_ENUM.ESCAPE ||
                  event.key === KEY_ENUM.ENTER
                ) {
                  closeDialog();
                }
              }}
              tabIndex="0"
            >
              <Icon icon="close" className="hub-intercept-modal__close-icon" />
            </IconButton>
          </div>
        )}
        <div className="hub-intercept-modal__content-container">
          {isTabletDn ? (
            <img
              src={HubAppIcon}
              alt="Hub App Icon for Apple Devices"
              className="hub-intercept-modal_app-icon"
            />
          ) : (
            <img
              src={HubPlatforms}
              alt="Various devices running the Nutrien Hub app"
              className="hub-intercept-modal_platform-image"
            />
          )}
          <div className="hub-intercept-modal__message-container">
            <div className="hub-intercept-modal__message-copy">
              <h2 data-test="hub-intercept-modal__message-title">
                {isNative
                  ? `Download the New Hub Today!`
                  : `Explore the New Hub Today!`}
              </h2>
              <p className="hub-intercept-modal__message">
                We've upgraded the Hub to make managing your account, making
                payments, and viewing agronomically relevant weather even
                easier. The new and improved Hub will be called Nutrien HUB when
                installed on your mobile device.
              </p>
              <p className="hub-intercept-modal__message__cxh-kill-date">
                This version of the Digital Hub will not be supported after May
                1, 2025.
              </p>
            </div>
            {isNative ? mobileAppleStoreButton() : desktopHubButton()}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

HubModalV2.propTypes = {
  isOpen: propTypes.bool.isRequired,
  closeDialog: propTypes.func.isRequired,
  preventDismiss: propTypes.bool.isRequired,
};

HubModalV2.defaultProps = {};

export default HubModalV2;
