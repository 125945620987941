import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Box, Button, IconButton } from '@material-ui/core';
import resolveHubUrl from 'shared/config/resolveHubUrl';
import KEY_ENUM from 'shared/utils/KeyCodes';
import HubPaymentsPage from 'assets/hub-payments-page.png';
import {
  Dialog,
  DialogContent,
  Icon,
} from 'nutrien-common-components-react/dist/mdc';

import './HubPaymentsModal.scss';

const HubPaymentsModal = ({ isOpen, closeDialog, accountId }) => {
  const hubUrl = resolveHubUrl();

  const handleCloseDialog = () => {
    const newPath = queryString.exclude(window.location.href, [
      'showHubPaymentsModal',
    ]);

    window.history.replaceState('', '', newPath);

    closeDialog();
  };

  const LinkOutButton = () => (
    <Box
      display="flex"
      paddingTop={2}
      className="hub-payments-modal__link-button"
    >
      <Button
        type="submit"
        variant="contained"
        onClick={() =>
          window.open(
            `${hubUrl}/account?accountId=${
              accountId || ''
            }&paymentDrawerOpen=opened`,
            '_blank'
          )
        }
      >
        Pay in New Nutrien Hub
      </Button>
    </Box>
  );

  return (
    <Dialog
      className="hub-payments-modal__container"
      open={isOpen}
      onClosing={() => closeDialog()}
    >
      <div className="hub-payments-modal__close-button">
        <IconButton
          aria-label="Click to close Hub Ad Dialog"
          onClick={handleCloseDialog}
          onKeyPress={(event) => {
            if (event.key === KEY_ENUM.ESCAPE || event.key === KEY_ENUM.ENTER) {
              handleCloseDialog();
            }
          }}
          tabIndex="0"
        >
          <Icon icon="close" />
        </IconButton>
      </div>
      <DialogContent className="hub-payments-modal__dialog-content">
        <div className="hub-payments-modal__title">
          Payments Have Moved to the New Nutrien Hub
        </div>
        <div className="hub-payments-modal__sub-title">
          All of your saved and historical payment data remains accessible in
          the New Nutrien Hub.
        </div>
        <img
          className="hub-payments-modal__image"
          src={HubPaymentsPage}
          alt="digital hub payments page"
        />
        <LinkOutButton />
      </DialogContent>
    </Dialog>
  );
};

HubPaymentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default HubPaymentsModal;
