import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, withStyles } from '@material-ui/core';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Button from '@nutrien/uet-react/Button';
import resolveHubUrl from 'shared/config/resolveHubUrl';
import BillingPreferenceItem from './BillingPreferenceItemContainer';
import { privacyPreferences } from './billingTabPropTypes';
import ConfirmationModal from './ConfirmationModal';
import ManagePaymentMethods from './ManagePaymentMethods/ManagePaymentMethodsContainer';

import styles from '../../styles';

const renderPreferenceItem = (preferenceItem, accountIds, hrClassName) => (
  <Fragment key={preferenceItem.preference}>
    <BillingPreferenceItem
      accountIds={accountIds}
      preference={preferenceItem}
    />
    <hr className={hrClassName} />
  </Fragment>
);

const renderPreferencesSection = (
  privacyPreferences, // eslint-disable-line no-shadow
  accountIds,
  togglingPreferences,
  errorLoadingPreferences,
  hrClassName
) => {
  if (togglingPreferences) {
    return <MaterialLoadingIcon />;
  }

  if (errorLoadingPreferences) {
    return <ApiErrorMessage entityName="Paperless preferences" />;
  }

  return (
    <>
      <List>
        {privacyPreferences.map((pref) =>
          renderPreferenceItem(pref, accountIds, hrClassName)
        )}
      </List>
      <ConfirmationModal />
    </>
  );
};

const BillingTab = ({
  classes,
  privacyPreferences, // eslint-disable-line no-shadow
  accountIds,
  togglingPreferences,
  errorLoadingPreferences,
}) => {
  const { cxhPaymentsRedirectToHub } = useFlags();
  const hubUrl = resolveHubUrl();

  const linkToHubSettings = () => {
    window.open(`${hubUrl}/more/settings`, '_blank');
  };

  return (
    <div>
      <div className={classes.sectionContainer}>
        {cxhPaymentsRedirectToHub ? (
          <>
            <div className={classes.manageHubTitle}>
              Paperless Billing & Payment Methods
            </div>
            <div>
              Easily manage your billing and payment methods in the new Nutrien
              HUB.
            </div>
            <Button
              variant="contained"
              onClick={linkToHubSettings}
              className={classes.manageHubButton}
            >
              Manage in New Nutrien Hub
            </Button>
          </>
        ) : (
          <>
            <div className={classes.sectionTitle}>Paperless Billing</div>
            {renderPreferencesSection(
              privacyPreferences,
              accountIds,
              togglingPreferences,
              errorLoadingPreferences,
              classes.hrFullWidth
            )}
            <ManagePaymentMethods />
          </>
        )}
      </div>
    </div>
  );
};

BillingTab.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  privacyPreferences: privacyPreferences.isRequired,
  accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  togglingPreferences: PropTypes.bool,
  errorLoadingPreferences: PropTypes.objectOf(PropTypes.string),
};

BillingTab.defaultProps = {
  togglingPreferences: false,
  errorLoadingPreferences: null,
};

export default withStyles(styles)(BillingTab);
